import * as React from "react"
import Layout from "../components/Layout"
import {StaticImage} from "gatsby-plugin-image";
import '../styles/cocktails.scss'
import Carousel from "react-multi-carousel";
import {useState} from "react";
import Dot from '../images/inline/dot.inline.svg'
import {graphql, Link} from "gatsby";
import {Trans, useI18next} from "gatsby-plugin-react-i18next";

const CocktailPage = () => {
    const {languages, changeLanguage, language} = useI18next();

    const responsive = {
        all: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 0},
            items: 1,
        }
    };

    const cocktails = [
        {
            name: language == 'es' ? 'Tommy Margarita' : 'Tommy Margarita',
            ingredientes: language == 'es' ? [
                '2 Oz Tequila Santaleza Reposado',
                '1 Oz jugo de limón, recién exprimido',
                '1/2 Oz nectar de agave Senor Maguey',
                'Guarnición: Borde de sal (opcional)'
            ] : [
                '2 ounces Santaleza Reposado tequila',
                '1 ounce lime juice, freshly squeezed',
                '1/2 ounce Senor Maguey agave nectar',
                'Garnish: salt rim (optional)'
            ],
            instrucciones: language == 'es' ? [
                'Frote el borde de un vaso bajo con una rodaja de lima, sumerja el borde en sal para cubrirlo y reserve. Combine Santaleza, jugo de limón, néctar de agave y hielo en una coctelera. Agitate bien y coloque sobre hielo fresco en el vaso con borde de sal.'
            ] : [
                'Rub the rim of a rocks glass with a lime wedge, dip the rim in salt to coat, and set aside. Combine Santaleza, lime juice, agave nectar and ice in a shaker. Shake well, strain over fresh ice in the salt-rimmed glass.'
            ],
            link: <Link to="tequilas/tequila-blanco">Santaleza Blanco</Link>,
            image: <StaticImage className="image" src={'../images/cocktails/tommy-margarita.webp'}
                                alt={'cocktail tequila blanco'}/>
        },
        {
            name: language == 'es' ? 'Paloma Santaleza' : 'Paloma Santaleza',
            ingredientes: language == 'es' ? [
                '59.14 mls de Tequila Santaleza Blanco',
                '14.78 mls de Jugo de limón recién exprimido',
                'Refresco de toronja frío',
                'Guarnición: rodaja de lima',
                'Guarnición: sal en el borde (opcional)'
            ] : [
                '2 oz Tequila Santaleza Blanco',
                '½ oz lime juice, freshly squeezed',
                'Grapefruit soda',
                'Garnish: lime wheel',
                'Garnish: salt rim (optional)',
            ],
            instrucciones: language == 'es' ? [
                'Frotar una rodaja de lima alrededor del borde del vaso, y sumergir en la sal (opcional)',
                'Agregar el tequila y el jugo de limón al vaso, con un poco de hielo',
                'Cubrir con refresco de toronja y revolver suavemente para combinar',
                'Adornar con una rodaja de lima'
            ]: [
                'Rub a lime wedge around the edge of a highball glass, and dip the rim in salt (optional)',
                'Add the tequila and lime juice to the glass, and fill with ice',
                'Top with grapefruit soda, and stir briefly and gently to combine',
                'Garnish with a lime wheel'
            ],
            link: <Link to="tequilas/tequila-blanco">Santaleza Blanco</Link>,
            image: <StaticImage className="image" src={'../images/cocktails/paloma-santaleza.webp'}
                                alt={'cocktail tequila blanco'}/>
        },
        {
            name: language == 'es' ? 'Tegroni cocktail' : 'Tegroni cocktail',
            ingredientes: language == 'es' ? [
                '1 Oz Tequila Santaleza Blanco',
                '1 Oz Botanika Angelica Bíter',
                '1 Oz Dos Deus vermouth blanco',
                'Guarnición: twist de pomelo'
            ] : [
                '1 ounce Santaleza Blanco tequila',
                '1 ounce Botanika Angelica Bitters',
                '1 ounce Dos Deus Blanc vermouth',
                'Garnish: grapefruit twist'
            ],
            instrucciones: language == 'es' ? [
                'Combine Sataleza Blanco, Botanika Angelica Bíter y Dos Deus vermouth blanco en un vaso mezclador con hielo y revuelva hasta que esté bien frío. Colar en un vaso bajo lleno de hielo fresco. Adorne con un twist de toronja.'
            ]: [
                'Combine Santaleza Blanco, Botanika Angelica Bitters and Dos Deus Blanc vermouth into a mixing glass with ice and stir until well-chilled. Strain into a rocks glass filled with fresh ice. Garnish with a grapefruit twist.'
            ],
            link: <Link to="tequilas/tegroni-cocktail">Tegroni cocktail</Link>,
            image: <StaticImage className="image" src={'../images/cocktails/tegroni.webp'}
                                alt={'cocktail Tegroni cocktail'}/>
        },
        // {
        //     name: language == 'es' ? 'Tequila Santaleza Añejo' : 'Tequila Santaleza Añejo',
        //     ingredientes: language == 'es' ? [
        //         '59.14 mls Santaleza Tequila Añejo',
        //         '7.39 mls Jarabe simple de vainilla',
        //         '2 pizcas de chocolate amargo',
        //         '1 Cáscara de toronja',
        //         '1 Cáscara de naranja'
        //     ] : [
        //         '2 oz Tequila Santaleza Añejo',
        //         '.25 oz Vanilla simple syrup',
        //         '2 dashes Chocolate bitters',
        //         '1 Grapefruit peel',
        //         '1 Orange peel for garnish '
        //     ],
        //     instrucciones: language == 'es' ? [
        //         'En un vaso mezclador combinar todos los ingredientes',
        //         'Colocar cubos de hielo grandes en un vaso doble old fashioned',
        //         'Adornar con ralladura de cáscara de naranja y toronja',
        //     ]: [
        //         'In a mixing glass, combine all the ingredients and stir with ice to chill',
        //         'Strain over large ice cubes in a double old fashioned glass',
        //         'Garnish with the expressed zest of an orange peel'
        //     ],
        //     link: <Link to="tequilas/tequila-anejo">Santaleza Añejo</Link>,
        //     image: <StaticImage className="image" src={'../images/cocktails/tequila-santaleza-blanco.jpg'}
        //                         alt={'cocktail tequila blanco'}/>
        // },
        {
            name: language == 'es' ? 'Chupacabra' : 'Chupacabra',
            ingredientes: language == 'es' ? [
                '.75oz Santaleza Blanco Tequila',
                '.75oz Botanika Angelica Orange Liqueur',
                '.75oz Dos Deus Dry Vermouth',
                '.75oz Lemon Juice',
                'Rosemary sprig garnish',
            ] : [
                '.75oz Santaleza Blanco Tequila',
                '.75oz Botanika Angelica Orange Liqueur',
                '.75oz Dos Deus Dry Vermouth',
                '.75oz Lemon Juice',
                'Rosemary sprig garnish',
            ],
            instrucciones: language == 'es' ? [
                'Combine all ingredients into a shaker tin',
                'Add ice',
                'Cap and shake vigorously for 8 seconds',
                'Double strain into a chilled cocktail glass',
                'Garnish with a sprig of rosemary'
            ]: [
                'Combine all ingredients into a shaker tin, add ice, cap and shake vigorously for 8 seconds. Double strain into a chilled cocktail glass. Garnish with a sprig of rosemary'
            ],
            link: <Link to="tequilas/tequila-blanco">Santaleza Blanco</Link>,
            image: <StaticImage className="image" src={'../images/cocktails/chupacabra.webp'}
                                alt={'cocktail tequila blanco'}/>
        },
        {
            name: language == 'es' ? 'Remember the Alamo' : 'Remember the Alamo',
            ingredientes: language == 'es' ? [
                '2oz Santaleza Blanco Tequila',
                '.5oz Botanika Angelica Elderflower Liqueur',
                '.5oz Dos Deus White Vermouth',
                '.25oz Botanika Angelica Amaro',
                'Lime twist garnish'
                
            ] : [
                '2oz Santaleza Blanco Tequila',
                '.5oz Botanika Angelica Elderflower Liqueur',
                '.5oz Dos Deus White Vermouth',
                '.25oz Botanika Angelica Amaro',
                'Lime twist garnish'
            ],
            instrucciones: language == 'es' ? [
                'Pour Amaro into glass and coat inside wholly, and discard any excess',
                'Combine all remaining ingredients (except for the lime twist) into a mixing glass, add ice and stir for 20 revolutions/until adequately chilled',
                'Use a hawthorne strainer to strain into a chilled Double Old Fashioned Glass over a large clear ice cube',
                'Zest and garnish with the lime twist'

            ]: [
                'Pour Amaro into glass and coat inside wholly, and discard any excess. Combine all remaining ingredients (except for the lime twist) into a mixing glass, add ice and stir for 20 revolutions/until adequately chilled. Use a hawthorne strainer to strain into a chilled Double Old Fashioned Glass over a large clear ice cube. Zest and garnish with the lime twist.'
            ],
            link: <Link to="tequilas/tequila-blanco">Santaleza Blanco</Link>,
            image: <StaticImage className="image" src={'../images/cocktails/remember_the_alamo.webp'}
                                alt={'cocktail tequila blanco'}/>
        },
        {
            name: language == 'es' ? 'No Jacket Required: A Dirty Tequila Martini' : 'No Jacket Required: A Dirty Tequila Martini',
            ingredientes: language == 'es' ? [
                '3oz Santaleza Blanco Tequila',
                '.25oz Dos Deus Dry White Vermouth ',
                '.25oz Gordal Olive Brine',
                '1 Dash Scrappy\'s Lime Bitters',
                'Garnish with 3 Gordal olives'
                
            ] : [
                '3oz Santaleza Blanco Tequila',
                '.25oz Dos Deus Dry White Vermouth ',
                '.25oz Gordal Olive Brine',
                '1 Dash Scrappy\'s Lime Bitters',
                'Garnish with 3 Gordal olives'
            ],
            instrucciones: language == 'es' ? [
                'Combine all ingredients (except for the olives) into a mixing glass',
                'Add ice and stir for 15 revolutions/until adequately chilled',
                'Use a hawthorne strainer to strain into a chilled Nick and Nora over the Gordal Olives'

            ]: [
                'Combine all ingredients (except for the olives) into a mixing glass, add ice and stir for 15 revolutions/until adequately chilled. Use a hawthorne strainer to strain into a chilled Nick and Nora over the Gordal Olives.'
            ],
            link: <Link to="tequilas/tequila-blanco">Santaleza Blanco</Link>,
            image: <StaticImage className="image" src={'../images/cocktails/no_jacket_required_cocktail.webp'}
                                alt={'cocktail tequila blanco'}/>
        },     
        {
            name: language == 'es' ? 'Electric Avenue: Cantorito' : 'Electric Avenue: Cantorito',
            ingredientes: language == 'es' ? [
                '2oz Santaleza Blanco Tequila',
                '5oz Cantaloupe Juice',
                '.75oz Grapefruit Juice',
                '.75oz Lime Juice',
                'Pinch of Salt',
                '3oz Jarritos Tamarind Soda'                
            ] : [
                '2 oz Santaleza Reposado Tequila',
                '1.5oz Cantaloupe Juice',
                '.75oz Grapefruit Juice',
                '.75oz Lime Juice',
                'Pinch of Salt',
                '3oz Jarritos Tamarind Soda'
            ],
            instrucciones: language == 'es' ? [
                'Combine all ingredients except Tamarind Soda into a shaker tin',
                'Add ice, cap and shake vigorously for 8 seconds',
                'Use a hawthorne strainer to strain into a chilled clay tumbler over ice',
                'Add Jarritos Tamarind Soda and Garnish with a lime wedge and tamarind chew candy'
            ]: [
                'Combine all ingredients except Tamarind Soda into a shaker tin, add ice, cap and shake vigorously for 8 seconds. Use a hawthorne strainer to strain into a chilled clay tumbler over ice. Add Jarritos Tamarind Soda and Garnish with a lime wedge and tamarind chew candy.'
            ],
            link: <Link to="tequilas/tequila-reposado">Santaleza Reposado</Link>,
            image: <StaticImage className="image" src={'../images/cocktails/electric-avenue-cantorito.webp'}
                                alt={'cocktail tequila blanco'}/>
        },
        {
            name: language == 'es' ? 'Highland Old Fashioned' : 'Highland Old Fashioned',
            ingredientes: language == 'es' ? [
                '5oz Santaleza Blanco Tequila',
                '.5oz Mezcal Burrito Fiestero Ensamble',
                '.5oz Ancho Reyes',
                '.125oz Senor Maguey Agave Nectar',
                '2 Drops Bittermen\'s Mole Bitters',
                '1 Dash Angostura Bitters',
                'Orange twist garnish'
            ] : [
                '1.5oz Santaleza Reposado Tequila',
                '.5oz Mezcal Burrito Fiestero Ensamble',
                '.5oz Ancho Reyes',
                '.125oz Senor Maguey Agave Nectar',
                '2 Drops Bittermen\'s Mole Bitters',
                '1 Dash Angostura Bitters',
                'Orange twist garnish'
            ],
            instrucciones: language == 'es' ? [
                'Combine all ingredients except the orange twist into a mixing glass',
                'Add ice and stir for 20 revolutions/until properly chilled',
                'Use a hawthorne strainer to strain into a chilled Double Old Fashioned Glass over a large clear ice cube',
                'Zest and Garnish with the orange twist and serve'
            ]: [
                'Combine all ingredients except the orange twist into a mixing glass, add ice and stir for 20 revolutions/until properly chilled. Use a hawthorne strainer to strain into a chilled Double Old Fashioned Glass over a large clear ice cube. Zest and Garnish with the orange twist and serve.'
            ],
            link: <Link to="tequilas/tequila-reposado">Santaleza Reposado</Link>,
            image: <StaticImage className="image" src={'../images/cocktails/highland-old-fashioned.webp'}
                                alt={'cocktail tequila blanco'}/>
        },
        {
            name: language == 'es' ? 'Serpente Rey' : 'Serpente Rey',
            ingredientes: language == 'es' ? [
                '2oz Santaleza Blanco Tequila',
                '.5oz Botanika Angelica Bitters',
                '.5oz Malt Liquor Oleo',
                '2oz Topo Chico',
                'Orange wheel and tajin garnish'
            ] : [
                '2oz Santaleza Reposado Tequila',
                '.5oz Botanika Angelica Bitters',
                '.5oz Malt Liquor Oleo',
                '2oz Topo Chico',
                'Orange wheel and tajin garnish'
            ],
            instrucciones: language == 'es' ? [
                'Combine all ingredients except for Topo Chico into a shaker tin',
                'Add ice, cap and shake vigorously for 8 seconds',
                'Use a hawthorne strainer to strain into a collins glass over ice',
                'Add Soda and garnish with Orange Wheel and Tajin',
                'Malt Liquor Oleo Prep: Add the peels of 4 oranges, cover with 1 cup of sugar, and set overnight. Once the liquid begins to form, mix well and set aside. Next, slowly reduce 40oz of Malt liquor to half and allow it to cool. Once cooled, add 20oz of malt liquor reduction to the oleo, mix till all sugar dissolves, strain and store in the fridge.'
            ]: [
                'Combine all ingredients except for Topo Chico into a shaker tin, add ice, cap and shake vigorously for 8 seconds. Use a hawthorne strainer to strain into a collins glass over ice. Add Soda and garnish with Orange Wheel and Tajin',
                'Malt Liquor Oleo Prep: Add the peels of 4 oranges, cover with 1 cup of sugar, and set overnight. Once the liquid begins to form, mix well and set aside. Next, slowly reduce 40oz of Malt liquor to half and allow it to cool. Once cooled, add 20oz of malt liquor reduction to the oleo, mix till all sugar dissolves, strain and store in the fridge'
            ],
            link: <Link to="tequilas/tequila-reposado">Santaleza Reposado</Link>,
            image: <StaticImage className="image" src={'../images/cocktails/serpente-rey.webp'}
                                alt={'cocktail tequila blanco'}/>
        }
    ]

    const [activeCocktail, setActiveCocktail] = useState(cocktails[0])

    return (
        <Layout>
            <title>Santaleza</title>

            <section className="main_section">

                <div className="main_logo">
                    <StaticImage src="../images/ouressence/pina_tequila_ilustracion.svg" alt="pina"/>
                    <h2 className="to"><Trans>Cocktails</Trans></h2>
                </div>

                <div className="main_image">
                    <StaticImage className="image" src="../images/cocktails/image_main_cocktail.jpg"
                                 alt="don vic"/>
                    <StaticImage className="stamp" src="../images/ouressence/sello_santaleza.svg"
                                 alt="sello santaleza"/>
                    <div className="main_scroll" onClick={() => {
                    typeof window !== "undefined" ? window[`scrollTo`]({top: 550, behavior: `smooth`}) : ''
                    }}>
                        <p className="spettw">SCROLL DOWN</p>
                        <StaticImage src="../images/ouressence/scroll_down.svg" alt="flecha abajo"/>
                    </div>

                </div>

            </section>

            <section className="coidealprod">
                <div className="dimensiones">
                    <StaticImage src="../images/cocktails/postal-jalisco.png" alt="postal jalisco"/>
                </div>

                <div className="top">
                    <p className="subtt">Cocktails</p>
                    <div className="cosubtitle">
                        <div className="covineta"><StaticImage src="../images/cocktails/vineta.png"
                                                               alt="vineta decorativa"/></div>
                        <h2 className="speto"><Trans>Ideal product</Trans></h2>
                    </div>
                </div>

            </section>

            <div className="cocktails">
                <div className="cocktail-carousel">
                    <Carousel responsive={responsive}
                              focusOnSelect
                              infinite
                              slidesToSlide={0}
                              additionalTransfrom={0}
                              arrows
                              draggable={false}
                              shouldResetAutoplay={false}
                              autoPlay={false}
                              centerMode
                              afterChange={(previousSlide, {currentSlide}) => {
                                  let realCurrent = currentSlide >= 2 ? (currentSlide - 2) : 0

                                  setActiveCocktail(cocktails[realCurrent])
                              }}
                    >
                        {cocktails.map((cocktail) => {
                            return (
                                <div className={'cocktail'}>
                                    {cocktail.image}
                                    <p>{cocktail.name}</p>
                                </div>
                            )
                        })}
                    </Carousel>
                </div>
                <div className="cocktail-detail">
                    <div className="top">
                        <h2>{activeCocktail.name}</h2>
                        <StaticImage src={'../images/our-tequila/separator.png'} alt={'cocktails'}/>
                    </div>
                    <div className="left">
                        {activeCocktail.image}
                    </div>
                    <div className="right">
                        <h5><Trans>Ingredients</Trans></h5>
                        {/* {activeCocktail.link} */}
                        {activeCocktail.ingredientes.map((ingrediente, index) => {
                            return (
                                <p key={index}><Dot/>{ingrediente}</p>
                            )
                        })}
                        <h5><Trans>Steps</Trans></h5>
                        {activeCocktail.instrucciones.map((instruccion, index) => {
                            return (
                                <p key={index}>{index+1}. {instruccion}</p>
                            )
                        })}
                    </div>
                </div>
            </div>

            <section className="fourth-section">
                <div className="back-to-top" onClick={() => {
                    typeof window !== "undefined" ? window[`scrollTo`]({top: 0, behavior: `smooth`}) : ''
                }}>
                    <StaticImage className="up-arrow" src="../images/home/back-top.svg" alt="flecha arriba"/>
                    <p className="parrafo text-back">BACK <br/> TO TOP</p>
                </div>
            </section>
        </Layout>
    )
}

export default CocktailPage

export const pageQuery = graphql`
     query CocktailsQuery ($language: String!) {
      locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    }
`